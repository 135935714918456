import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { Menu, Button } from 'semantic-ui-react';
import { FaChevronCircleRight } from 'react-icons/fa';
import { Waypoint } from 'react-waypoint';

import Layout from '../components/layout';

const colors = [
  'red',
  'orange',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'brown',
];

const SponsorshipPage = ({ data }) => {
  const [activeItem, setActiveItem] = useState('why');
  const [showBackToTop, toggleBackToTop] = useState(false);

  const { sanityPage: sponsorshipPageContent, menu: menuPdf } = data;

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
  };

  return (
    <Layout
      headerImage={sponsorshipPageContent.headerImage.asset}
      headerText={sponsorshipPageContent.title}
      headerSize="sm"
    >
      <section>
        <h1>Emmaus Sponsorship</h1>
        <p>
          Good sponsorship is vital to the integrity of Emmaus. Many communities
          take sponsorship for granted; they assume that everyone knows the how
          and why of sponsorship. Yet sponsorship is the most important job in
          Emmaus. It is more than just “signing up people”. The quality of
          sponsorship influences the pilgrim, the health of the Emmaus Movement,
          and the church affected by the movement.
        </p>
        <StyledMenu vertical inverted>
          <Menu.Item
            name="why-do-we-sponsor"
            active={activeItem === 'why-do-we-sponsor'}
            onClick={handleItemClick}
            color={colors[0]}
            href="#why-do-we-sponsor"
          >
            <FaChevronCircleRight />
            Why do we sponsor?
          </Menu.Item>

          <Menu.Item
            name="whom"
            active={activeItem === 'whom'}
            onClick={handleItemClick}
            color={colors[1]}
            href="#whom-do-we-sponsor"
          >
            <FaChevronCircleRight />
            Whom do we sponsor?
          </Menu.Item>

          <Menu.Item
            name="how"
            active={activeItem === 'how'}
            onClick={handleItemClick}
            color={colors[2]}
            href="#how-do-we-sponsor"
          >
            <FaChevronCircleRight /> How do we sponsor?
          </Menu.Item>

          <Menu.Item
            name="who can sponsor"
            active={activeItem === 'who can sponsor'}
            onClick={handleItemClick}
            color={colors[3]}
            href="#who-can-sponsor"
          >
            <FaChevronCircleRight /> Who can sponsor?
          </Menu.Item>

          <Menu.Item
            name="what should i tell"
            active={activeItem === 'what should i tell'}
            onClick={handleItemClick}
            color={colors[4]}
            href="#what-should-i-tell-my-pilgrim"
          >
            <FaChevronCircleRight /> What should I tell my pilgrim?
          </Menu.Item>

          <Menu.Item
            name="dietary-needs"
            active={activeItem === 'dietary-needs'}
            onClick={handleItemClick}
            color={colors[6]}
            href="#dietary-needs"
          >
            <FaChevronCircleRight /> Special Dietary Needs
          </Menu.Item>

          <Menu.Item
            name="application"
            active={activeItem === 'application'}
            onClick={handleItemClick}
            color={colors[5]}
            href="#application"
          >
            <FaChevronCircleRight /> Application
          </Menu.Item>

          <Menu.Item
            name="resources"
            active={activeItem === 'resources'}
            onClick={handleItemClick}
            color={colors[6]}
            href="#resources"
          >
            <FaChevronCircleRight /> Resources
          </Menu.Item>
        </StyledMenu>

        <p>
          Good sponsorship is your first act of agape before a Walk ever begins;
          the experience of the Walk for a pilgrim really starts with how we
          handle sponsorship.
        </p>
        <p>
          Good sponsorship undergirds the whole weekend with sacrificial love on
          behalf of each pilgrim. Sponsors use discernment in recruiting
          pilgrims, embody the personal commitment of the community to each
          pilgrim, and provide personal acts of agape during the three days for
          the pilgrims. These acts of agape include prayer, agape letters, and
          presence at Send off, Candlelight and Closing, and follow-up.
        </p>
        <p>
          Good sponsorship is the foundation for a healthy, effective Emmaus
          movement that is fulfilling its true purpose - the development of
          Christian leaders and the renewal of the church in ministry. The
          strength of any Emmaus community is a direct result of its recruiting
          practices. If the community is committed to recruiting strong church
          leaders for the purpose of strengthening the local church, then the
          community will be a strong, vital force in the renewal movement. If,
          on the other hand, the Walk to Emmaus is looked upon as a hospital
          where every human ill can be cured, it will have a weakening effect on
          the entire community.
        </p>
        <AnchorTag id="why-do-we-sponsor">
          <h2>Why Do We Sponsor?</h2>
        </AnchorTag>
        <p>
          The aim of a sponsor should not be to “get all my friends to go”, to
          fill up the weekend, to fix people's problems, or to reproduce one's
          own religious experience in others. Rather, the aim of the sponsor is
          to bring spiritual revitalization to Christians who, in turn, will
          bring new life and vision to the work of the church in the
          congregation, home, workplace, and community. The aim of sponsorship
          is to build up the Body of Christ.
        </p>
        <Waypoint
          onEnter={() => toggleBackToTop(true)}
          onLeave={() => toggleBackToTop(false)}
        >
          <div>
            <AnchorTag id="whom-do-we-sponsor">
              <h2>Whom Do We Sponsor?</h2>
            </AnchorTag>
            <p>
              One's awareness of, and commitment to, the purpose of Emmaus
              influences who is sponsored and how they are sponsored. Emmaus is
              for active Christians and members of churches whose own renewal
              will mean new energy, commitment, and vision in the church and
              everyday environments for Christ's sake. There are several
              qualities a prospective pilgrim should possess.
            </p>
            <ul>
              <li>
                The person should already be on a pilgrimage, willing to grow
                and move forward in their journey of faith.
              </li>
              <li>
                The person should have a Christian passion. The pilgrim knows
                God can make and has made a difference in their life.
              </li>
              <li>
                The person isn’t so consumed by life’s circumstances that they
                are unable to give full attention to the message and experience
                of Emmaus.
              </li>
            </ul>
            <p>Those sponsored could include the following:</p>
            <ul>
              <li>
                Church leaders (pastors and laypersons) who will bring new
                vision, commitment, and understanding back to their
                congregations and who need the renewal and grace Emmaus
                channels;
              </li>
              <li>
                Dependable church members who are the quiet backbone of the
                church;
              </li>
              <li>
                Less active members who need their awareness of grace rekindled
                and their commitments renewed;
              </li>
              <li>
                Christians who are hungry for “something more” and who want to
                grow spiritually;
              </li>
              <li>
                Respected laypersons and clergypersons whose participation,
                support, and leadership will encourage others to attend the Walk
                and will build a sound, balanced leadership base for the
                movement in the community; and
              </li>
              <li>
                Members of diverse congregations, denominations, and ethnic
                groups.
              </li>
            </ul>
            <p>
              Emmaus is right for many people - but not for everyone. The
              religious background or emotional condition of some people may
              make Emmaus an improper discipleship tool for them. Others may be
              unsuitable for sponsorship because of the negative effect they
              might have on an Emmaus weekend or the divisive influence they
              might bring to the church. Sponsorship requires sensitivity to
              these factors.
            </p>
            <p>Some examples of questionable sponsorship are as follows:</p>
            <ul>
              <li>Non-Christians</li>
              <li>
                Christians whose theology and/or practice is notably different
                or incompatible with the traditional theology and practice
                represented by The Walk to Emmaus;
              </li>
              <li>
                persons undergoing an emotional crisis (for example, family
                breakup, job loss, severe grief) or who are psychologically
                unstable;
              </li>
              <li>
                persons who decide not to attend a Walk after being presented
                the opportunity. A potential sponsor need not feel like a
                failure if a prospect says no. Perhaps the timing is not right.
                Perhaps God will renew the person in another way. Remember, The
                Walk to Emmaus is not the way to renewal for every Christian.
              </li>
            </ul>
            <p>
              Wise sponsorship is purposeful and prayerful; unwise sponsorship
              is haphazard and undiscerning. Wise sponsorship eventually will
              produce a balanced, theologically centered movement of the Holy
              Spirit. Unwise sponsorship eventually will produce a harvest that
              becomes more of a burden than a blessing for the church.
            </p>
            <AnchorTag id="how-do-we-sponsor">
              <h2>How Do We Sponsor?</h2>
            </AnchorTag>
            <ol>
              <li>
                Pray for the person's openness to God's call to discipleship,
                not for how to get him or her to go on a Walk.
              </li>
              <li>
                Extend an invitation. Invite the person to attend for the sake
                of a more vital relationship with Jesus Christ, not just an
                event to go to. Share your faith; explain the basic elements of
                the Walk, its purpose, and follow-up dimensions, which help us
                live in grace for the rest of our lives. Take the attitude that
                you are offering the person a wonderful gift rather than that
                they need to go.
              </li>
              <li>
                Ask the person to make a commitment by filling out the
                registration form. If the person is married, speak with both
                partners and encourage an equal commitment by both. ALL parts of
                the application must be completed and signed.
              </li>
              <li>
                Continue to pray for your prospective pilgrim. Once the pilgrim
                has been assigned to a Walk, begin to prepare agape letters. Do
                not send personal agape for the pilgrim to be delivered during
                the weekend. Pilgrims are not allowed to receive individual
                agape during the weekend.
              </li>
              <li>
                Support the Walk through your participation in the 72-Hour
                Prayer Vigil and your presence at Sendoff, Candlelight and
                Closing. Support the pilgrim's family by house-sitting,
                baby-sitting, feeding pets, watering plants, or just checking in
                with a spouse to see if any help is needed. For this reason it
                is best not to sponsor more than one pilgrim or couple at a
                time.
              </li>
              <li>
                Encourage the pilgrim in his or her Fourth Day involvement. Talk
                to them about their weekend experience. Help the pilgrim find or
                start a reunion group. Take the pilgrim to the first Gathering
                after their Walk. You could even offer to baby-sit so your
                pilgrim can attend a Candlelight.
              </li>
              <li>
                Help the pilgrim re-enter their church and consider ways to
                engage with new commitment and enthusiasm.
              </li>
              <li>
                Inform the pilgrim about ways to serve the Emmaus community on
                future Walks, creating agape and writing letters to other
                pilgrims.
              </li>
              <li>Help the pilgrim understand how to sponsor others.</li>
            </ol>
            <AnchorTag id="who-can-sponsor">
              <h2>Who Can Sponsor?</h2>
            </AnchorTag>
            <p>
              Any persons who have participated in Emmaus can sponsor another
              person to Emmaus so long as they understand the aim and
              responsibilities of sponsorship and can fulfill them for the
              person they would sponsor. Persons who have participated in
              Chrysalis can also sponsor adults to Emmaus under the same
              conditions.
            </p>
            <AnchorTag id="what-should-i-tell-my-pilgrim">
              <h2>What Should I Tell My Pilgrim?</h2>
            </AnchorTag>
            <p>
              While a pilgrim may not know what questions to ask, you do not
              need to tell them everything that will occur during the three
              days. However, keep in mind there are no secrets! Saying to a
              pilgrim that you can’t tell them anything about the Walk
              potentially creates unnecessary suspicion and anxiety. And for
              clergy and staff leadership, this posture builds a wall of
              division resulting in an “us vs. them” mentality against the
              Emmaus community. There is no reason to shroud the event in
              secrecy. This practice has probably done more harm to the movement
              than people realize. You can share many things about Emmaus
              without revealing some of the unique and meaningful experiences.
            </p>
            <p>Here are some suggested items to cover with a pilgrim.</p>
            <ul>
              <li>
                There are fifteen presentations (five each day) dealing with
                subjects like setting priorities, serving God in everyday
                situations, how to be a disciple, and how to persevere in faith.
                Encourage note taking.
              </li>
              <li>There is time spent in discussing these presentations.</li>
              <li>Time is spent in prayer and meditation.</li>
              <li>Communion is observed each day.</li>
              <li>There is plenty of good food and fellowship.</li>
              <li>
                Discuss sleeping and showering arrangements with your pilgrim. A
                list of suggested items to bring is included with the pilgrim’s
                welcome and acceptance letter.
              </li>
              <li>
                No contact with the outside world except, of course, for
                emergencies.
              </li>
              <li>
                Tell how each weekend is unique, but each person seems to find
                exactly what God wants that person to experience. Don’t try to
                pre-judge what a person’s needs are or what someone will get out
                of the Walk to Emmaus. Simply tell your own story. There are no
                expected results other than experiencing God’s love.
              </li>
            </ul>
            <AnchorTag id="dietary-needs">
              <h2>Special Dietary Needs</h2>
            </AnchorTag>
            <p>
              Special dietary needs have increased over the past years. These
              guidelines are for medical diets. All other diets which, are a
              participant’s choice, are theirs to monitor per our standard menu.
            </p>

            <ol style={{ padding: 0, listStyle: 'none' }}>
              <li>
                For special dietary needs, the participant (Emmaus, Chrysalis,
                or team member) will need to bring their own meals (3 breakfast,
                3 lunch, 2 supper) with additional instructions if necessary. We
                also think it will be best if the Kitchen Coordinator can go
                over this with the participant and their sponsor when they
                arrive for the event. We would prefer that the meals be already
                prepared and in separate containers marked for each meal
                (breakfast, lunch, supper), but if they bring items in bulk we
                can prepare their meals per their instructions.
              </li>
              <li>
                If a participant (Emmaus, Chrysalis, team member) is a Thursday
                last minute addition, we realize that they will not have
                sufficient time to prepare their meals if they have a special
                dietary need. We will need to talk with the participant (Emmaus,
                Chrysalis, team member) and their sponsor to see what their
                dietary need is so we can do our best to meet that need.
              </li>
              <li>
                A Quick Menu Guide is available{' '}
                {menuPdf ? (
                  <a
                    href={menuPdf.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                ) : (
                  'via email'
                )}
                . This will allow the participant to view our standard menu so
                that they will be able to adjust their diet as needed.
              </li>
              <li>
                The Monday before the Walk or Flight begins, we request that the
                Lay Director and/or Board Advisor contact Bev Shepherd to advise
                the number of participants with special diets, their name and
                dietary concern.
              </li>
            </ol>

            <p>
              The Christian Camp of North Mississippi wants each participant to
              be at ease with their dietary restrictions which will enable them
              to fully enjoy their 72 hours with Christ. The kitchen crew will
              also be more at ease and confident that they will be able to meet
              the needs of the participant.
            </p>
            <AnchorTag id="application">
              <h2>The Application</h2>
            </AnchorTag>
            <p>
              A copy of the Request for Pilgrim Reservation and the Sponsor
              Sheet can be found in the “Blue Book” given to all pilgrims and
              team members at the end of each walk. The most current copies of
              these forms can be found by clicking below.
            </p>
            <p>
              ALL parts of both forms must be completed and signed. Please
              consider each item on the Sponsor Sheet carefully and prayerfully
              consider your responsibility before signing your name. Also, make
              certain the the sponsor’s contact information on the Pilgrim
              Reservation form is accurate. Incomplete applications cannot be
              processed.
            </p>
            <p>
              Both forms should be mailed to the Registrar by the sponsor. All
              communications with the Registrar should be from the sponsor
              unless circumstances dictate otherwise.
            </p>
            <p>
              <Link to="/applications">
                <button type="button" className="ui positive button">
                  View Applications
                </button>
              </Link>
            </p>
            <AnchorTag id="resources">
              <h2>Suggested Resources</h2>
            </AnchorTag>
            <p>
              Much of the material contained in this document is written and
              excerpted from the following two resources that are available
              through
              <a
                href="http://www.upperroom.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Upper Room website
              </a>
              .
            </p>
            <p>
              <em>Day Four: The Pilgrim's Continued Journey</em> by Robert Wood
              &amp; Marie Livingston Roy
            </p>
            <p>
              <em>The Upper Room Handbook on Emmaus, 2nd Edition</em> by Stephen
              Bryant &amp; Richard Gilmore
            </p>
            <p>
              Also consider these excellent resources (also available through
              The Upper Room website):
            </p>
            <p>
              <em>Sponsorship</em> by Richard &amp; Janine Gilmore – The Emmaus
              Library series
            </p>
            <p>
              <em>What is Emmaus?</em> by Stephen Bryant – The Emmaus Library
              series
            </p>
          </div>
        </Waypoint>
      </section>

      {
        <BackToTopButton
          circular
          icon="arrow up"
          color="pink"
          onClick={() => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = document.body.scrollTop;
          }}
          style={{ opacity: showBackToTop ? 1 : 0 }}
        />
      }
    </Layout>
  );
};

SponsorshipPage.propTypes = {
  data: PropTypes.shape().isRequired,
};

const StyledMenu = styled(Menu)`
  float: right;
  width: 200px;

  &.ui.menu {
    margin: calc(1rem - 1em) 0 1rem 1rem;

    .item {
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: 8px;
      flex-shrink: 0;
    }
  }

  &.ui.inverted.menu {
    box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.2);
  }

  &.ui.inverted.menu .item {
    background: #fff;
    color: #333;
    border: 1px solid rgba(34, 36, 38, 0.15);

    &:not(.active):hover {
      background: #ddd;
      color: #333;
    }
  }
`;

const AnchorTag = styled.div`
  &:before {
    content: '';
    display: block;
    /* header height + empty space */
    padding-top: 150px;
    /* header height to degrees padding’s space */
    margin-top: -150px;
  }
`;

const BackToTopButton = styled(Button)`
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  transition: 2000ms opacity linear;
`;

export default SponsorshipPage;

export const sponsorshipPageQuery = graphql`
  query SponsorshipPage($id: String!) {
    sanityPage(id: { eq: $id }) {
      id
      title
      _rawBody
      slug {
        current
      }
      headerImage {
        asset {
          fluid(maxWidth: 1600) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    menu: sanityFileAsset(originalFilename: { regex: "/menu/i" }) {
      id
      url
      originalFilename
    }
  }
`;
